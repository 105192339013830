import Box from "@mui/system/Box";
import Divider from "@mui/material/Divider";
import { Button, useMediaQuery } from "@mui/material";

const Footer = () => {
  const mobileView1 = useMediaQuery(
    "@media (max-width: 953px) and (max-height: 1180px)"
  );
  const mobileView2 = useMediaQuery(
    "@media (width: 360px) and (height: 740px)"
  );
  const mobileView3 = useMediaQuery(
    "@media (width: 375px) and (height: 667px)"
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        bgcolor: "#0E1116",
        position: "sticky",
        bottom: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 14,
        boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.75)",
        ...(mobileView1 && { gap: 2 }),
        ...(mobileView2 && { gap: 1 }),
        ...(mobileView3 && { gap: 1 }),
      }}
    >
      <a href="https://www.tetherre.com/#about">
        <Button
          variant="text"
          sx={{
            color: "#2BA3D7",
            fontSize: 17,
            ...(mobileView1 && { fontSize: 13 }),
          }}
        >
          About us
        </Button>
      </a>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ backgroundColor: "#FFFFFF" }}
      />
      <a href="https://www.tetherre.com/tether-privacy-policy/">
        <Button
          variant="text"
          sx={{
            color: "#2BA3D7",
            fontSize: 17,
            ...(mobileView1 && { fontSize: 13 }),
          }}
        >
          Privacy Policy
        </Button>
      </a>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ backgroundColor: "#FFFFFF" }}
      />
      <a href="https://www.tetherre.com/contact-us/">
        <Button
          variant="text"
          sx={{
            color: "#2BA3D7",
            fontSize: 17,
            ...(mobileView1 && { fontSize: 13 }),
          }}
        >
          Contact us
        </Button>
      </a>
    </Box>
  );
};

export default Footer;
