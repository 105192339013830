import { useEffect } from "react";
const DownloadRedirect = () => {
  const googleStoreLink =
    "https://play.google.com/store/apps/details?id=com.tetherre.app";
  const appleStoreLink = "https://apps.apple.com/us/app/tether-re/id1671685723";

  useEffect(() => {
    // detectPhonePlatform();
    const delay = 1500;
    const timeout = setTimeout(() => {
      detectPhonePlatform();
    }, delay);
    return () => clearTimeout(timeout);
  });

  const styles = {
    redirecting: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
  };

  const detectPhonePlatform = () => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isApple = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    // USE WHEN GOOGLE PLAY PASSES
    if (isAndroid) {
      window.location.href = googleStoreLink;
    } else if (isApple) {
      window.location.href = appleStoreLink;
    }
  };

  return (
    <>
      <div style={styles.redirecting}>Redirecting...</div>
    </>
  );
};

export default DownloadRedirect;
