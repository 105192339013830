import { useEffect, useState } from "react";
import { Button } from "@mui/material";

import "./index.css";
import QRCode from "qrcode.react";
import tetherreLogo from "../assets/new_tetherre_backdrop.jpg";
import tetherrePoint from "../assets/TetherREShieldIcon.svg";
import tetherreNameAndLogo from "../assets/TetherREHorizLogo2.svg";
import googlePlayStore from "../assets/google-play-badge-logo-svgrepo-com.svg";
import applePlayStore from "../assets/apple-store.svg";
import Footer from "../components/Footer";

const DownloadPage = () => {
  const [formLogo, setFormLogo] = useState(tetherrePoint);
  const [qrSize, setQrSize] = useState(220);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024 && window.innerHeight <= 1368) {
        setFormLogo(tetherreNameAndLogo);
        setQrSize(170);
      } else {
        setFormLogo(tetherrePoint);
        setQrSize(200);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const redirectLink = "https://download.tetherre.com/redirect";
  // TEST LINKS
  // "http://192.168.12.244:3000/redirect";

  const googleStoreLink =
    "https://play.google.com/store/apps/details?id=com.tetherre.app";
  const appleStoreLink = "https://apps.apple.com/us/app/tether-re/id1671685723";

  return (
    <>
      <div className="dl-page-container">
        <div className="dl-image-container">
          <img src={tetherreLogo} alt="Tetherre-Logo" />
        </div>
        <div className="dl-form-container">
          <div className="dl-tether-point-container">
            <img
              className="dl-tether-point"
              src={formLogo}
              alt="Tetherre-Point"
            />
          </div>
          <div className="dl-signin-header">Download our app!</div>
          <div className="qr-code">
            <QRCode value={redirectLink} renderAs="svg" size={qrSize} />
          </div>
          <div className="playstore-container">
            <a href={googleStoreLink}>
              <Button className="playstore-button">
                <img
                  className="google-playstore"
                  src={googlePlayStore}
                  alt="Google Play Store"
                />
              </Button>
            </a>
            <a href={appleStoreLink}>
              <Button className="playstore-button">
                <img
                  className="apple-playstore"
                  src={applePlayStore}
                  alt="Apple Play Store"
                />
              </Button>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DownloadPage;
