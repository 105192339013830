// import React from "react";
// import logo from "./logo.svg";
import { Route, Routes } from "react-router-dom";
import DownloadPage from "./DownloadPage";
import DownloadRedirect from "./DownloadPage/DownloadRedirect";

function App() {
  return (
    <Routes>
      <Route path="/" element={<DownloadPage />}></Route>
      <Route path="/redirect" element={<DownloadRedirect />} />
    </Routes>
  );
}

export default App;
